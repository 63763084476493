@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900");
#root {
  height: 100vh;
  font-family: "Montserrat";
}
.gx-main-content-wrapper {
  position: relative;
  height: 100%;
}

.ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.logo {
  font-size: 20px;
  height: 72px;
  line-height: 64px;
  text-align: center;
  cursor: pointer;
}

ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.sider-footer.ant-menu-inline-collapsed {
  width: 100% !important;
}

.menu-divider-space {
  padding: 0 20px;
}
.menu-divider {
  border-right: 1px dotted silver;
}
.menu-divider.notification {
  text-align: center;
  font-size: 20px;
}
.menu-divider.notification i {
  font-size: 20px;
}
.header .user a {
  color: inherit;
}
.ant-menu-vertical > .ant-menu-item.menu-list,
.ant-menu-vertical-left > .ant-menu-item.menu-list,
.ant-menu-vertical-right > .ant-menu-item.menu-list,
.ant-menu-inline > .ant-menu-item.menu-list {
  height: 40px;
  line-height: 40px;
  font-size: 17px;
  margin-top: 0px;
}
.ant-menu-vertical > .ant-menu-item.menu-list i,
.ant-menu-vertical-left > .ant-menu-item.menu-list i,
.ant-menu-vertical-right > .ant-menu-item.menu-list i,
.ant-menu-inline > .ant-menu-item.menu-list i {
  font-size: 17px;
}

.anticon.custom-discussions {
  transform: translate3D(0, -1px, 0) scale(1.33);
}

.anticon.custom-discussions svg {
  height: 14px;
}

.ant-menu-item:hover .anticon.custom-discussions {
  fill: #1890ff;
}

.ant-menu-item:hover:not(.ant-menu-item-selected) .discussion-icon,
.ant-menu-item:hover:not(.ant-menu-item-selected) .subscriber-icon {
  filter: invert(0.4) sepia(1) saturate(5) hue-rotate(175deg);
}

.ant-menu-item-selected .anticon.custom-discussions {
  filter: invert(1);
}

.ant-menu-item-selected .anticon.anticon.custom-users {
  filter: invert(1);
}

.ant-menu-submenu.menu-list {
  position: absolute;
  bottom: 48px;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid rgb(232, 232, 232);
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title {
  font-size: 17px;
  line-height: 40px;
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title > div > span {
  margin-right: 0.5em;
  margin-left: -0.5em;
}

.ant-menu.moderationMenu .moderationMenuItem {
  height: 64px;
  line-height: 64px;
  font-size: 16px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.menu-list {
  background-color: #1890ff !important;
}

.ant-menu-item-selected.menu-list > a,
.ant-menu-item-selected.menu-list > a:hover {
  color: #ffffff !important;
}

.ant-menu-item-selected.menu-list .anticon.custom-discussions {
  fill: #ffffff !important;
}

.ant-menu-item.menu-list::after {
  border-right: 3px solid #1890ff !important;
}

.ant-menu-horizontal.moderationMenu > .ant-menu-item-selected > a,
.ant-menu-horizontal.moderationMenu > .ant-menu-item > a:hover {
  color: #1890ff !important;
}

.ant-menu-horizontal.moderationMenu > .ant-menu-item-selected,
.ant-menu-horizontal.moderationMenu > .ant-menu-item:hover {
  border-bottom: 2px solid #1890ff;
}

.ant-menu-vertical .ant-menu-item.menu-list:not(:last-child),
.ant-menu-vertical-left .ant-menu-item.menu-list:not(:last-child),
.ant-menu-vertical-right .ant-menu-item.menu-list:not(:last-child),
.ant-menu-inline .ant-menu-item.menu-list:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-submenu.menu-list .ant-menu-submenu-title > div {
  white-space: pre-line;
}
