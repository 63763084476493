.profileImage-wrapper {
  text-align: center;
}
.profileImage-wrapper img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.is-inactive {
  background-color: #ffefef;
}
.is-inactive:hover {
  background-color: #ffefef;
}

.ant-table-wrapper.user-table {
  overflow-y: auto;
  height: calc(100vh - 120px);
}
.anticon.anticon-close.toast-close {
  margin-left: 16px;
  color: #a6a6a6;
}
