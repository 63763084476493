.login-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper {
  padding: 50px;
  border-radius: 5px;
  background: #f2f2f2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.27);
}
.login-wrapper .login-form-wrapper .header {
  margin-bottom: 20px;
}
.login-wrapper .login-form-wrapper .body {
  margin-top: 20px;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.google-button {
  width: 100%;
  margin-bottom: 10px;
}
.apple-button{
  width: 100%;
  background-color: black;
  color: white;
}
