.ant-menu-horizontal.moderationMenu > .ant-menu-item-selected,
.ant-menu-horizontal.moderationMenu > .ant-menu-item:hover {
  border-bottom: 2px solid #1890ff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border: none;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  transition: none;
}

.ant-layout-header .ant-menu-horizontal {
  font-size: 18px;
  width: 100%;
}
.ant-layout .gx-layout-content {
  overflow-x: hidden;
  overflow-y: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.moderation-filters {
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  .search-component {
    width: 250px;
  }
  .ant-picker-range {
    width: 250px;
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    width: 150px;
  }
}

.moderation-content {
  padding: 14px 28px;
  .video-card {
    .ant-card-body {
      .comment-list-card {
        height: 130px;
      }
      max-height: calc(100vh - 167px);
      overflow: auto;
    }
  }
  .post-comment-card .comment-card > .ant-card-body {
    height: calc(100vh - 167px);
    overflow: auto;
  }
}

.post-title {
  margin-top: 14px !important;
}

.divider {
  margin: 12px 0;
}

.ant-card.ant-card-bordered.parent.comment-card {
  background: #f5f5f5;
}

.live-post-card {
  height: calc(100vh - 165px);
}

.selected-post {
  border: 2px solid black;
}

.live-video {
  video {
    background: black;
  }
}
.live-video .live-post-video-tag {
  margin-top: 3px;
}
.comment-media {
  max-height: 250px;
  object-fit: contain;
  margin-bottom: 12px;
}
.side-card {
  height: calc(100vh - 167px);
}
.side-add-comment-card {
  height: calc(100vh - 260px);
  overflow: auto;
}
.side-comment-card {
  height: calc(100vh - 190px);
  overflow: auto;
}
.virtuoso {
  height: calc(100vh - 170px);
}
.post-comment-virtuoso{
  height: calc(100vh - 210px);
}
.post-add-comment-virtuoso{
  height: calc(100vh - 273px);
}
.side-virtuoso {
  height: 410px;
}
.reply-virtuoso {
  height: 680px;
}
.reply-header {
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff;
}
.play-button {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  color: white;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  cursor: pointer;
}
.play-button.ant-btn:hover {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}
.play-button.ant-btn:focus {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}
.post-play-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  color: white;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  cursor: pointer;
}
.post-play-button.ant-btn:hover {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}
.post-play-button.ant-btn:focus {
  color: white;
  background: rgba(0, 0, 0, 0.2);
}
.moderation-action-btn {
  color: white;
  background-color: #595959;
  border-color: #595959;
}
.moderation-action-btn.ant-btn:hover {
  color: white;
  background-color: #595959;
  border-color: #595959;
}
.moderation-action-btn.ant-btn:focus {
  color: white;
  background-color: #595959;
  border-color: #595959;
}
.media-container {
  position: relative;
  margin-bottom: 5px;
  padding: 20px;
  border: 1px dotted black;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  object-fit: contain;
  background-color: black;
}
.video-container > img {
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  min-height: 100%;
  object-fit: contain;
}
.image-container {
  max-width: 100%;
  max-height: 350px;
  min-height: 100%;
}
.image-container > img {
  max-width: 100%;
  min-width: 100%;
  max-height: 350px;
  min-height: 100%;
  object-fit: contain;
}

.remove-btn {
  position: absolute;
  top: -5px;
  right: 0px;
  color: #d90000;
  z-index: 999;
  font-size: 24px;
}
.comment-media {
  max-width: 100%;
  width: 350px;
  max-height: 250px;
  object-fit: contain;
  object-position: left;
}
.star-color {
  color: #ebd45c;
}

.comment {
  .user-section{
    width: 60%;
  }
  .comment-text {
    margin-top: 14px;
    color: #262626;
    word-break: break-word !important;
  }
  .moderation-tag-wrapper {
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 8px;
  }
  .moderation-tag-wrapper .ant-tag {
    font-size: 12px;
    line-height: 16px;
  }
}
.add-comment{
  position: absolute;
  bottom: 0;
  width: 96%;
}
.action-buttons-modal {
  .ant-btn-dangerous {
    background-color: #ff4d4f;
    color: #fff;
  }
}

.image-gif {
  width: 100%;
  height: 100%;
}
.activity-logs{
  color: #8c8c8c;
}